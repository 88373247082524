import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
const ifAuthenticated = (to: any, from: any, next: any) => {
  if (localStorage.userKB) {
    next();
    return;
  }
  next("/login");
};

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!localStorage.userKB) {
    next();
    return;
  }
  next("/");
};

const routes: RouteConfig[] = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/giftPrint/:id",
  //   name: "giftPrint",
  //   component: () => import("../views/gift_print.vue"),
  //   // beforeEnter: ifNotAuthenticated,
  // },

  {
    path: "/service/:slug",
    name: "service",
    component: () => import("../views/service.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/booking/:id",
    name: "booking",
    component: () => import("../views/booking-new.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/Payment/:id",
    name: "Payment",
    component: () => import("../views/bay.vue"),
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: "/PaymentGift/:id/:amount",
  //   name: "PaymentGift",
  //   component: () => import("../views/bay-gift.vue"),
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/PaymentCart/:id/:amount",
    name: "PaymentCart",
    component: () => import("../views/bay-cart.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/PaymentPackage/:id/:amount",
    name: "PaymentPackage",
    component: () => import("../views/bay-package.vue"),
    beforeEnter: ifAuthenticated,
  },
  // {
  //   path: "/gift",
  //   name: "gift",
  //   component: () => import("../views/gift.vue"),
  //   beforeEnter: ifAuthenticated,
  // },

  {
    path: "/packages",
    name: "packages",
    component: () => import("../views/packages.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/packages/:slug",
    name: "packagesOne",
    component: () => import("../views/packages.vue"),
    beforeEnter: ifAuthenticated,
  },

  {
    path: "/page/:slug",
    name: "page",
    component: () => import("../views/page.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/Impressum",
    name: "Impressum",
    component: () => import("../views/Impressum.vue"),
    // beforeEnter: ifNotAuthenticated,
  },



  {
    path: "/user/:id",
    name: "user",
    component: () => import("../views/user.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/contactUs",
    name: "contactUs",
    component: () => import("../views/contactUs.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/faqs",
    name: "faqs",
    component: () => import("../views/faqs.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/team",
    name: "team",
    component: () => import("../views/team.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/Production/:slug",
    name: "Production",
    component: () => import("../views/Production.vue"),
  },

  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/gallery.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/bayDone/:screen/:id/:done",
    name: "bayDone",
    component: () => import("../views/bayDone.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/Product-single/:id",
    name: "Product-single",
    component: () => import("../views/blog-single.vue"),
    // beforeEnter: ifNotAuthenticated,
  },

  {
    path: "/google-map",
    name: "google-map",
    component: () => import("../views/google-map.vue"),
    // beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("../views/cart.vue"),
    beforeEnter: ifAuthenticated,
  },

  // Authentication
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/regstar",
    name: "regstar",
    component: () => import("../views/auth/regstar.vue"),
    beforeEnter: ifNotAuthenticated,
  },

  // myprofile

  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("../views/profile/myprofile.vue"),
    beforeEnter: ifAuthenticated,
  },

  // {
  //   path: "/mygifts",
  //   name: "mygifts",
  //   component: () => import("../views/profile/mygifts.vue"),
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: "/gifts",
  //   name: "gifts",
  //   component: () => import("../views/profile/gifts.vue"),
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/packagesuser",
    name: "packagesuser",
    component: () => import("../views/profile/packagesuser.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/myreservations",
    name: "myreservations",
    component: () => import("../views/profile/myreservations.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/onereservations/:id",
    name: "onereservations",
    component: () => import("../views/profile/onereservations.vue"),
    beforeEnter: ifAuthenticated,
  },


  {
    path: "/supportTickets",
    name: "supportTickets",
    component: () => import("../views/profile/supportTickets.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/onesupportTickets/:id",
    name: "onesupportTickets",
    component: () => import("../views/profile/onesupportTickets.vue"),
    beforeEnter: ifAuthenticated,
  },

  // Authentication
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/login.vue"),
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: "/regstar",
    name: "regstar",
    component: () => import("../views/auth/regstar.vue"),
    beforeEnter: ifNotAuthenticated,
  },

  // myprofile

  {
    path: "/myprofile",
    name: "myprofile",
    component: () => import("../views/profile/myprofile.vue"),
    beforeEnter: ifAuthenticated,
  },

  // {
  //   path: "/mygifts",
  //   name: "mygifts",
  //   component: () => import("../views/profile/mygifts.vue"),
  //   beforeEnter: ifAuthenticated,
  // },
  // {
  //   path: "/gifts",
  //   name: "gifts",
  //   component: () => import("../views/profile/gifts.vue"),
  //   beforeEnter: ifAuthenticated,
  // },
  {
    path: "/packagesuser",
    name: "packagesuser",
    component: () => import("../views/profile/packagesuser.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/myreservations",
    name: "myreservations",
    component: () => import("../views/profile/myreservations.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/onereservations/:id",
    name: "onereservations",
    component: () => import("../views/profile/onereservations.vue"),
    beforeEnter: ifAuthenticated,
  },


  {
    path: "/supportTickets",
    name: "supportTickets",
    component: () => import("../views/profile/supportTickets.vue"),
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/onesupportTickets/:id",
    name: "onesupportTickets",
    component: () => import("../views/profile/onesupportTickets.vue"),
    beforeEnter: ifAuthenticated,
  },



  

  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
